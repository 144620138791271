import * as React from "react"
import { useState, useCallback } from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../../css/gallery.scss"
import LightGallery from 'lightgallery/react'
import "../../css/testimonial.scss"

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const onInit = () => {
	console.log('lightGallery has been initialized');
};

const CompletedHomesPage = () => {
  const [activeGallery, setActiveGallery] = useState(null)

  const build24Images = [
    { src: "/build-24/IMG_4227.jpg" },
    { src: "/build-24/current-build-1-23-1.jpg" },
    { src: "/build-24/current-build-1-23-2.jpg" },
    { src: "/build-24/current-build-1-23-3.jpg" },
    { src: "/build-24/IMG_1441.jpg" },
    { src: "/build-24/IMG_1396.jpg" },
    { src: "/build-24/current-build-image.jpg" },
    { src: "/build-24/IMG_1032.jpg" },
    { src: "/build-24/IMG_1078.jpg" },
    { src: "/build-24/IMG_1026.jpg" },
    { src: "/build-24/IMG_1027.jpg" },
    { src: "/build-24/IMG_1025.jpg" },
    { src: "/build-24/IMG_0869.jpg" },
    { src: "/build-24/IMG_0865.jpg" },
    { src: "/build-24/IMG_0618.jpg" },
    { src: "/build-24/IMG_0620.jpg" },
    { src: "/build-24/IMG_0626.jpg" },
    { src: "/build-24/IMG_0629.jpg" },
    { src: "/build-24/thayer-street-3.jpg" },
    { src: "/build-24/thayer-street-6.jpg" },
  ];

  const build25Images = [
    { src: "/build-25/build-25-1.jpg" },
    { src: "/build-25/build-25-2.jpg" },
    { src: "/build-25/build-25-3.jpg" },
    { src: "/build-25/build-25-4.jpg" },
    { src: "/build-25/build-25-5.jpg" },
    { src: "/build-25/build-25-6.jpg" },
    { src: "/build-25/build-25-7.jpg" },
  ];

  return (
    <Layout>
      <section id="hero">
        <StaticImage src="../../images/hero-images/hero-image-2.jpg" alt="Hero Background" className="heroImage" />
        <div className="heroOverlay"></div>
        <div className="container">
          <h1>Completed Homes</h1>
        </div>
      </section>
      <section id="pageContent">
        <div className="container withSidebar">
          <main>
            <p><strong>Stability</strong>: A Habitat home is a stabilizing force for a family. Better, affordable living conditions lead to improved health, stronger childhood development and the ability — and financial flexibility — to make forward-looking choices.</p>

            <div className="spacer"></div>

            <h3>Build 24 - Rhinelander</h3>
            <div className="gallery">
              <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                {build24Images.map((image, index) => (
                  <a key={index} href={image.src}>
                    <img alt="Build 24 - Rhinelander" src={image.src} />
                  </a>
                ))}
              </LightGallery>
            </div>

            <div className="spacer"></div>
            <div className="spacer"></div>

            <h3>Build 25 - Rhinelander</h3>
            <div className="gallery">
              <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                {build25Images.map((image, index) => (
                  <a key={index} href={image.src}>
                    <img alt="Build 25 - Rhinelander" src={image.src} />
                  </a>
                ))}
              </LightGallery>
            </div>

            <div className="spacer"></div>
            <div className="spacer"></div>

            <div className="testimonial">
              <StaticImage src="../../images/completed-builds/phelps.JPG" alt="Phelps Testimonial" className="testimonialImage" />
              <div className="testimonialContent">
                <h6>
                  "Habitat for Humanity is a fantastic group of people with generous, caring hearts who are making dreams come true.
                  We will always be grateful for our time working with them and will always remember them with fondness in our hearts."
                </h6>
                <p>- Rebecca Robbins (Habitat homeowner from Phelps)</p>
              </div>
            </div>

            <div className="testimonial">
              <div className="testimonialContent">
                <h6>
                  "My Habitat for Humanity home was my chance for independence. As a single mom this was huge. I couldn't be happier."
                </h6>
                <p>- Belit Babilius (Habitat homeowner from Lac du Flambeau)</p>
              </div>
              <StaticImage src="../../images/completed-builds/lac-du-flambeau.JPG" alt="Lac Du Flambeau Testimonial" className="testimonialImage" />
            </div>

          </main>
          <Sidebar />
        </div>
      </section>
    </Layout>
  )
}
export default CompletedHomesPage

export const Head = () => (
  <Seo
    title="Completed Homes | Habitat For Humanity Northwoods Wisconsin"
  />
)
